import Env from "app/core/environment";

let env = Env.getInstance(),
    repositoryURL = env.getMainImageRepository();

export default [
    // first
    [
        {
            desktop: {
                original: `${repositoryURL}/top-banners/first-order--desktop.svg`
                // preview: `${repositoryURL}/top-banners/bepanten/bepanten-desktop3-preview.svg`
            },
            mobile: {
                original: `${repositoryURL}/top-banners/first-order--mobile.svg`
                // preview: `${repositoryURL}/top-banners/bepanten/bepanten-mobile3-preview.svg`
            },
            background: "#333266",
            url: "https://www.apteka24.ua/mobile-app/",
            title: "100₴ на перше замовлення від 1000₴ через додаток"
        }
        // {
        //     desktop: {
        //         original: `${repositoryURL}/top-banners/bepanten/bepanten-desktop3-2.jpg`
        //     },
        //     mobile: {
        //         original: `${repositoryURL}/top-banners/bepanten/bepanten-mobile3-2.jpg`
        //     },
        //     background: "#D4F5FF",
        //     url: "https://www.apteka24.ua/mnn/bepanten/?utm_source=banner&utm_medium=top&utm_campaign=bepanten",
        //     title: "Бепантен. Обирай турботу про себе та малюка"
        // },
        // {
        //     desktop: {
        //         original: `${repositoryURL}/top-banners/bepanten/bepanten-desktop3-3.jpg`
        //     },
        //     mobile: {
        //         original: `${repositoryURL}/top-banners/bepanten/bepanten-mobile3-3.jpg`
        //     },
        //     background: "#D4F5FF",
        //     url: "https://www.apteka24.ua/mnn/bepanten/?utm_source=banner&utm_medium=top&utm_campaign=bepanten",
        //     title: "Бепантен. Обирай турботу про себе та малюка"
        // }
    ]

    // second
    // [
    //     {
    //         desktop: {
    //             original: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-desktop3-1.jpg`
    //             // preview: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-desktop3-preview.svg`
    //         },
    //         mobile: {
    //             original: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-mobile3-1.jpg`
    //             // preview: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-mobile3-preview.svg`
    //         },
    //         background: "#D4F5FF",
    //         url: "https://www.apteka24.ua/bepanten-plyus-krem-5-30g-dekspantenol/?utm_source=banner&utm_medium=top&utm_campaign=bepanten_plus",
    //         title: "Бепантен плюс. Знезараження та загоєння ранок без болю"
    //     },
    //     {
    //         desktop: {
    //             original: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-desktop3-2.jpg`
    //         },
    //         mobile: {
    //             original: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-mobile3-2.jpg`
    //         },
    //         background: "#D4F5FF",
    //         url: "https://www.apteka24.ua/bepanten-plyus-krem-5-30g-dekspantenol/?utm_source=banner&utm_medium=top&utm_campaign=bepanten_plus",
    //         title: "Бепантен плюс. Знезараження та загоєння ранок без болю"
    //     },
    //     {
    //         desktop: {
    //             original: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-desktop3-3.jpg`
    //         },
    //         mobile: {
    //             original: `${repositoryURL}/top-banners/bepantenPlus/bepantenPlus-mobile3-3.jpg`
    //         },
    //         background: "linear-gradient(180deg, #BBE0F2 0%, #DDF9FC 100%)",
    //         url: "https://www.apteka24.ua/bepanten-plyus-krem-5-30g-dekspantenol/?utm_source=banner&utm_medium=top&utm_campaign=bepanten_plus",
    //         title: "Бепантен плюс. Знезараження та загоєння ранок без болю"
    //     }
    // ]
];

// export default [
//     {
//         preview: {
//             mobile: {
//                 original: `${repositoryURL}/top-banners/bepanten/bepanten-desktop3-1.jpg`
//             }
//         }
//     }
// ];
