import _ from "lodash";
import {isNode} from "browser-or-node";

import React from "react";
import App from "next/app";
import Head from "next/head";

// eslint-disable-next-line no-unused-vars
import _JSXStyle from "styled-jsx/style";

// import SmartBanner from 'react-smartbanner';

import Env from "app/core/environment";

import RouteNameEnum from "app/core/utilites/enum/route";
import LanguageEnum from "app/core/utilites/enum/language";

import DeviceDetector from "app/core/utilites/deviceDetector";
import Router from "app/core/utilites/router";

import Analytics from "app/core/analytics";
import MetaTagService from "app/core/services/metaTag";

import PromoBanner from "app/core/services/promoBanner";

import {appWithTranslation} from "config/i18n";

import "a24-style-guide/dist/css/main.css";

// import "styles/style.scss";
import styles from "styles/style.module.scss";
import Resource from "app/core/resource";

// @appWithTranslation
class Apteka24 extends App {
    constructor(props) {
        super(props);

        /**
         * @property _smartBannerName
         * @type {string}
         * @private
         */
        this._smartBannerName = "apteka24.ua";

        /**
         * @property _reopeningSmartBannerAfterDays
         * @type {number}
         * @private
         */
        this._reopeningSmartBannerAfterDays = 7;

        /**
         * @property _deepLinks
         * @type {{homePage: {android: string, ios: string}}}
         * @private
         */
        this._deepLinks = {
            homePage: {
                ios: "",
                android: ""
            }
        };

        /**
         * @private
         * @property stringsResource
         * @type {Object}
         */
        this._stringsResource = Resource.getStrings(Env.getInstance().getLanguage());

        this.env = Env.getInstance();
        this.router = Router.getInstance();
        this.analytics = Analytics.getInstance();

        /**
         * @property _routeNameEnum
         * @type {Enum}
         * @private
         */
        this._routeNameEnum = RouteNameEnum.getInstance();

        DeviceDetector.getInstance().init(props.userAgent);
    }

    /**
     * @protected
     * @method componentDidMount
     * @returns {void}
     */
    componentDidMount() {
        this.analytics.init();

        if (window.location.pathname !== this.props.pageInfo.url) {
            this.router.replace(this.props.pageInfo.url, {
                shallow: true
            });
        }
    }

    /**
     * @private
     * @method _getMetaData
     * @returns {Object}
     */
    _getMetaData() {
        return this.props.pageInfo.metadata || {};
    }

    /**
     * @private
     * @method _getMetaTags
     * @returns {Object}
     */
    _getMetaTags() {
        return this._getMetaData().meta || [];
    }

    /**
     * @private
     * @method _getMetaLinks
     * @returns {Array}
     */
    _getMetaLinks() {
        return this._getMetaData().links || [];
    }

    /**
     * @private
     * @method _getTitle
     * @returns {string}
     */
    _getTitle() {
        return this._getMetaData().title || "";
    }

    /**
     * @private
     * @method _getDescription
     * @returns {string}
     */
    _getDescription() {
        let description = this._getMetaTags().find((tag) => tag.name === "description");

        return description ? description.content : "";
    }

    /**
     * @private
     * @method _getEsputnikWebPushScript
     * @returns {string}
     */
    _getEsputnikWebPushScript() {
        return `(function(i,s,o,g,r,a,m){ i["esSdk"] = r; i[r] = i[r] || function() { (i[r].q = i[r].q || []).push(arguments) }, a=s.createElement(o), m=s.getElementsByTagName(o)[0]; a.async=1; a.src=g; m.parentNode.insertBefore(a,m)} ) (window, document, "script", "https://esputnik.com/scripts/v1/public/scripts?apiKey=eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0NTI0ZWZhYTJkYzI2MGRmYTM4YTE1NDBlMWFhYjE0N2Q1OTAzNTBlMjk0YmQxOTI1Y2ZlMGU2YWQzYzEwMDRmMjJhOGU1MzE1ZmJlYTIyZTBhMDMzY2FhODI3MTg0NTI1MmFhYTM0NjEwNjUzNGMxZTcyMjRhOTU2MTVlYmU0MmJkZDhlYjZkNmIyMDc0NWFhNDY3YjEyZWIwODM0NzEyMGU4ZWVkOTA5OTU3In0.VLdUZbCKfgCm_hzMWWtZyDDTcY7N_N5lKeYR1CTjVfYu3GSw9Mt3vqMVmWUe2_Va1roaNBa7eMaWNiUkAxnjUQ&domain=2AA490BB-A850-4CFB-9DF9-F6F828376391", "es"); es("pushOn");`;
    }

    /**
     * @private
     * @method _startWorker
     * @returns {Apteka24}
     */
    _startWorker() {
        if (!isNode) {
            // eslint-disable-next-line no-new
            new Worker("./sw.js");
        }

        return this;
    }

    /**
     * @private
     * @method _renderMetaTags
     * @returns {Array}
     */
    _renderMetaTags() {
        let appMarketTags = ["apple-itunes-app", "google-play-app"];

        return this._getMetaTags().filter(function (item) {
            return !appMarketTags.includes(item.name);
        }).map(function (item, index) {
            return <meta key={`meta-${index}`} {...item} />;
        });
    }

    /**
     * @private
     * @method _renderLinks
     * @returns {Array}
     */
    _renderLinks() {
        let appMarketIcons = ["android-touch-icon", "apple-touch-icon"];

        return this._getMetaLinks().filter(function (item) {
            return !appMarketIcons.includes(item.rel);
        }).map(function (item, index) {
            return <link key={`link-${index}`} {...item} />;
        });
    }

    /**
     * @public
     * @method render
     * @returns {string}
     */
    render() {
        let {
            Component, hasError, pageProps, pageInfo
        } = this.props;

        _.merge(pageInfo, {
            metadata: {
                description: this._getDescription()
            }
        });

        this._startWorker();

        return (
            <>
                <Head>
                    <title>{this._getTitle()}</title>

                    <meta charSet="UTF-8" />
                    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />

                    <meta name="robots" content="noindex, nofollow" />

                    <link rel="manifest" href="/manifest.json" />

                    {/* <link
                        rel="preload"
                        href={PromoBanner.getInstance().getPromoBanner().preview.mobile.original}
                        as="image"
                    /> */}

                    {!isNode && (
                        <script
                            dangerouslySetInnerHTML={{__html: this._getEsputnikWebPushScript()}}
                        />
                    )}

                    {this._renderMetaTags()}
                    {this._renderLinks()}
                </Head>

                <style jsx>
                    {styles}
                </style>

                {/*{this._routeNameEnum.isHome(pageInfo.type) && (*/}
                {/*    <SmartBanner*/}
                {/*        title={this._smartBannerName}*/}
                {/*        daysHidden={this._reopeningSmartBannerAfterDays}*/}
                {/*        daysReminder={this._reopeningSmartBannerAfterDays}*/}
                {/*        url={{ios: this._deepLinks.homePage.ios, android: this._deepLinks.homePage.android}}*/}
                {/*        button={this._stringsResource.view}*/}
                {/*    />*/}
                {/*)}*/}

                <Component hasError={hasError} {...pageProps} pageInfo={pageInfo} />
            </>
        );
    }
}

/**
 * @static
 * @method getCurrentUrlByContext
 * @param context {Object}
 * @returns {string}
 */
Apteka24.getCurrentUrlByContext = function (context) {
    return `${Env.getInstance().getBitrixHost()}${context.asPath}`;
};

/**
 * @static
 * @method getCurrentUrlByContext
 * @param context {Object}
 * @returns {string}
 */
Apteka24.getLanguageByContext = function (context) {
    return (context.req && context.req.language) || context.query.subpath || LanguageEnum.getInstance().getRuAsValue();
};

/**
 * @example
 *
 * redirect = {
 *  url: string,
 *  statusCode: number
 * }
 *
 * @static
 * @method redirectTo
 * @param response {Object}
 * @param redirect {Object}
 * @returns {void}
 */
Apteka24.redirectTo = function (response, redirect) {
    if (isNode) {
        response.redirect(redirect.statusCode, redirect.url);
    } else {
        window.location.href = redirect.url;
    }
};

/**
 * @property
 * @type {string[]}
 */
Apteka24.ignoredURL = [
    "/json",
    "/json/",
    "/uk/json",
    "/uk/json/",
    "/favicon.ico",
    "/favicon.ico/",
    "/uk/favicon.ico",
    "/uk/favicon.ico/"
];

/**
 * @static
 * @method getInitialProps
 * @param params {Object}
 * @returns {Promise}
 */
Apteka24.getInitialProps = function (params) {
    let {Component, ctx} = params,
        props = {
            currentUrl: isNode ? `${ctx.req.protocol}://${ctx.req.hostname}${ctx.req.url}` : window.location.href,
            hasError: false,
            pageInfo: {
                language: ""
            },
            pageProps: {
                breadcrumbs: [],
                initialData: {},
                promoBanner: PromoBanner.getInstance().getPromoBanner()
            },
            namespacesRequired: ["common"],
            userAgent: isNode ? ctx.req.headers["user-agent"] : window.navigator.userAgent
        };

    if (isNode && Apteka24.ignoredURL.includes(ctx.req.url)) { //@TODO temporary hook !
        ctx.res.statusCode = 404;

        return Promise.resolve(props);
    }

    ctx.language = Apteka24.getLanguageByContext(ctx);

    return new Promise((resolve) => {
        MetaTagService.getInstance().getMetaTags(
            Apteka24.getCurrentUrlByContext(ctx),
            ctx.language,
            function (pageInfo) {
                if (pageInfo.redirect && pageInfo.redirect.url) {
                    Apteka24.redirectTo(ctx.res, pageInfo.redirect);
                } else {
                    props.pageInfo = pageInfo;
                    props.pageInfo.language = ctx.language;
                    props.pageProps.breadcrumbs = pageInfo.breadcrumbs;
                    props.pageProps.initialData = pageInfo.data || {};

                    resolve(pageInfo);
                }
            },
            function () {
                props.pageInfo = {
                    language: ctx.language
                };

                // return resolve({});

                // eslint-disable-next-line no-unreachable
                props.hasError = true;

                props.pageInfo = {
                    language: ctx.language,
                    metadata: {
                        title: "Ошибка 404 - страница не найдена | Аптека24",
                        meta: []
                    }
                };

                if (isNode) {
                    ctx.res.statusCode = 404;
                }

                resolve(props.pageInfo);
            }
        );
    }).then(() => new Promise((resolve) => {
        Promise.resolve(Component.getInitialProps(ctx, props)).then((initialData) => {
            props.pageProps.initialData = _.merge({}, initialData, props.pageProps.initialData);

            resolve(props);
        });
    }));
};

export default appWithTranslation(Apteka24);
